/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAction } from '@reduxjs/toolkit';

export const handleSuccessAct = createAction<{ message: string }>('[App] show success message');

function errorSerializer(payload: { error: any }) {
  const { error } = payload;

  if (error && error.status === 500) {
    return {
      payload: {
        error: {
          data: 'Internal Server Error',
        },
      },
    };
  }

  return { payload };
}

// @ts-ignore
export const handleErrorAct = createAction<{ error: any }>('[App] handle error message', errorSerializer);
