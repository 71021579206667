import * as Sentry from '@sentry/nextjs';

import { isHMR, isProd } from '../src/config';

export const enabled = !isHMR;

export function init() {
  if (!enabled) {
    return;
  }

  const dsn =
    process.env.SENTRY_DSN ||
    process.env.NEXT_PUBLIC_SENTRY_DSN ||
    'https://5f719115928641b68ca6db774cc56dde@o545354.ingest.sentry.io/5667118';

  Sentry.init({
    dsn,
    enabled: true,
    environment: isProd ? 'production' : 'development',
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 0.2,
    // allowUrls: [/https?:\/\/((devfront|www)\.)?smartpost\.global/],
    ignoreErrors: [
      // CookieBot specific
      'Cookiebot: Cookiebot was unable',
      "Cannot read properties of null (reading 'insertBefore')",
      // Some 3d party libs
      "Uncaught DOMException: Failed to execute 'removeChild' on 'Node'",
      'Node.removeChild: The node to be removed is not a child of this node',
      // FB pixel
      "Can't find variable: fbq",
    ],
  });
}
