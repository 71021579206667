import { all, call, fork, put, takeLatest } from 'redux-saga/effects';

import { i18n } from '../../i18n';

import { hideLoader, showLoader } from '../app/actions';
import * as appActs from '../../store/actions';
import * as acts from './actions';

import * as client from '../../client';

function* feedbackWorker({ payload: { message } }: ReturnType<typeof acts.sendFeedBackAct>) {
  yield put(showLoader());

  try {
    // make API call
    yield call(client.postFeedBack, message);

    // show success alert
    yield put(appActs.handleSuccessAct({ message: i18n.t('contacts:contacts.alerts.success') }));
  } catch (error) {
    // handle any error
    yield put(
      appActs.handleErrorAct({
        error: error.response,
      })
    );
  }

  yield put(hideLoader());
}

function* feedbackWatcher() {
  yield takeLatest(acts.sendFeedBackAct, feedbackWorker);
}

export default function* root() {
  yield all([fork(feedbackWatcher)]);
}
