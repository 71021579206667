import { createReducer } from '@reduxjs/toolkit';

import { initialState } from './state';
import * as acts from './actions';

export default createReducer(initialState, {
  [acts.clean.toString()]: () => ({
    ...initialState,
  }),

  [acts.fetchPaymentData.toString()]: (state, { payload: { slug } }: ReturnType<typeof acts.fetchPaymentData>) => ({
    ...state,
    slug,
    details: null,
  }),

  [acts.fetchPaymentDataSuccess.toString()]: (
    state,
    { payload: { details } }: ReturnType<typeof acts.fetchPaymentDataSuccess>
  ) => ({
    ...state,
    details,
  }),

  [acts.setStep.toString()]: (state, { payload: { step } }: ReturnType<typeof acts.setStep>) => ({
    ...state,
    step,
  }),

  [acts.changeStatusSuccess.toString()]: (
    state,
    { payload: { status } }: ReturnType<typeof acts.changeStatusSuccess>
  ) => {
    if (state.details) {
      state.details.status = status;
    }
  },

  [acts.createTicketRequestSuccess.toString()]: (
    state,
    { payload: { ticket } }: ReturnType<typeof acts.createTicketRequestSuccess>
  ) => {
    state.ticket = ticket;
  },
});
