import Router from 'next/router';
import { all, call, debounce, put, select, takeLatest } from 'redux-saga/effects';

import { wrapSaga } from '../../../store/sagas/helpers';

import { isTestEnv } from '../../../config';

import { actions as ratesActs, DeliveryType, StepType } from '../../rates';
import * as acts from '../actions';

import * as selectors from '../selectors';
import { formStateSelector, serviceSelector } from './delivers.selectors';
import { parcelHasDocs } from '../form.selector';

import * as client from '../../../client';
import { filterInboundProviders } from '../../../client/services/adatapter/country-mapper';

import { getLangCode } from '../../../helpers/i18n';

import { getProviderService, Providers } from '../../../config/providers';
import { Country, getProviderCountry } from '../../../config/countries';
import { toProvider } from '../../../config/country-slug';

import { ProviderFieldMode } from '../state';
import { i18n } from '../../../i18n';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function disableServices<T>(delivers: T[], from: string): T[] {
  // disable postomat for InPost live
  if (from === 'pl') {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return delivers.map((delivery: any) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { postomat, ...services } = delivery.services;

      return { ...delivery, services: { ...services } };
    });
  }

  // disable no_docs for Hermes live
  if (from === 'uk') {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return delivers.map((delivery: any) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { no_docs, ...services } = delivery.services;

      return { ...delivery, services: { ...services } };
    });
  }

  return delivers;
}

function* cleanWorker() {
  const can = yield select(selectors.isDelivers);

  if (!can) {
    return;
  }

  yield put(ratesActs.resetState());
}

function* providersPairWorker() {
  const from: Country = yield select(selectors.getDispatchCountry);
  const to: Country = yield select(selectors.getInboundCountry);

  const hasPair = yield select(selectors.hasProvidersPair);

  if (!hasPair) {
    const providersPair = yield call(client.getEnabledProviders);

    yield put(
      acts.setProvidersPairAct({
        providersPair,
      })
    );
  }

  const providersPair = yield select(selectors.getProvidersPair);
  const inboundList: Providers[] = yield call(filterInboundProviders, from.code, providersPair);

  yield put(
    acts.setInboundListAct({
      inboundList,
    })
  );

  const isBlocked = !inboundList.includes(to.provider);

  if (isBlocked) {
    const [inboundProvider] = inboundList;

    const newToCountry = getProviderCountry(inboundProvider);

    yield put(acts.setCountryAct({ from: from.code, to: newToCountry?.code }));
  }
}

function* worker() {
  const can = yield select(selectors.isDelivers);

  if (!can) {
    return;
  }

  const { step, from, to, weight, width, height, length } = yield select(formStateSelector);

  if (step === StepType.search) {
    let delivers = yield call(client.getDelivers, {
      country_code: from,
      lang: getLangCode(),
      weight,
      width: width * 10,
      height: height * 10,
      length: length * 10,
      destination_code: to,
    });

    if (!isTestEnv) {
      delivers = disableServices<typeof delivers>(delivers, from);
    }

    yield put(acts.setDelivers({ delivers }));
  } else if (step === StepType.form) {
    const { provider } = yield select(selectors.getDispatchCountry);
    const { from: origin, to, service, deliveryType, template } = yield select(serviceSelector);
    const hasDocs = yield select(parcelHasDocs);

    const options = template
      ? { template }
      : {
          width: width * 10,
          height: height * 10,
          length: length * 10,
          max: 'true',
        };

    const priceParams = {
      origin_service: origin,
      destination_service: to,
      origin_type: getProviderService(provider, service),
      weight,
      no_docs: !hasDocs,
      ...options,
    };

    const [courier, department] = yield all([
      call(client.getDetailedPrice, {
        ...priceParams,
        deliver_type: DeliveryType.courier,
      }),
      call(client.getDetailedPrice, {
        ...priceParams,
        deliver_type: DeliveryType.department,
      }),
    ]);

    const pricing = DeliveryType.courier === deliveryType ? courier : department;

    yield put(acts.setPrice(pricing));
    yield put(
      acts.setDeliveryDiff({
        courier,
        department,
      })
    );
  }
}

function* providerURLWorker() {
  const mode: ProviderFieldMode = yield select(selectors.getMode);

  const bookService = yield select(selectors.getBookService);
  const dispatchCountry: Country = yield select(selectors.getDispatchCountry);
  const inboundCountry: Country = yield select(selectors.getInboundCountry);

  if (mode === 'delivers') {
    const { language: code } = i18n;

    const url = `/${code}${toProvider(dispatchCountry.provider, inboundCountry.provider, bookService)}`;
    const diff = Router.asPath !== url;

    if (diff) {
      yield call(Router.replace, url);
    }
  }
}

export function* providersPairWatcher() {
  yield debounce(500, [acts.setMode, acts.setCountryAct, acts.fetchProvidersPairAct], function* () {
    yield call(wrapSaga, providersPairWorker);
  });
}

export function* cleanWatcher() {
  yield takeLatest(acts.setCountryAct, cleanWorker);
}

export function* deliversWatcher() {
  yield debounce(
    500,
    [
      ratesActs.setDelivery,
      acts.setMode,
      acts.setDocs,
      acts.bookService,
      acts.setCountryAct,
      acts.setWeightAct,
      acts.setWidthAct,
      acts.setHeightAct,
      acts.setLengthAct,
      acts.setParcelTemplate,
    ],
    function* () {
      yield call(wrapSaga, worker);
    }
  );
}

export function* providerURLWatcher() {
  yield debounce(500, [acts.setCountryAct, acts.bookService], function* () {
    yield call(wrapSaga, providerURLWorker);
  });
}
