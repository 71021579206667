import { all, call, fork, put, takeLatest } from 'redux-saga/effects';

import { hideLoader, showLoader } from '../app/actions';
import * as appActs from '../../store/actions';
import * as acts from './actions';

import * as client from '../../client';

function* trackingWorker({ payload: { barcode } }: ReturnType<typeof acts.fetchTracking>) {
  yield put(showLoader());

  try {
    const list = yield call(client.getTracking, barcode);
    yield put(acts.fetchTrackingSuccess({ list }));
  } catch (error) {
    const { data } = error.response;

    yield put(
      appActs.handleErrorAct({
        error: {
          data,
        },
      })
    );
  }

  yield put(hideLoader());
}

function* trackingWatcher() {
  yield takeLatest(acts.fetchTracking, trackingWorker);
}

export default function* root() {
  yield all([fork(trackingWatcher)]);
}
