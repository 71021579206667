import { createAction } from '@reduxjs/toolkit';

import { BankingDetails, PayLinkStatus, PayLinkTicket } from '../../client';
import { LinkStep } from './state';

export const clean = createAction('Clean payment state');

export const fetchPaymentData = createAction<{ slug: string }>('Load payment data');
export const fetchPaymentDataSuccess = createAction<{ details: BankingDetails }>('Load payment data success');

export const setStep = createAction<{ step: LinkStep }>('Change form step');

export const createTicketRequest = createAction('Create ticket request');
export const createTicketRequestSuccess = createAction<{ ticket: PayLinkTicket }>('Ticked created successfully');

export const changeStatusRequest = createAction<{ status: PayLinkStatus }>('Change status request');
export const changeStatusSuccess = createAction<{ slug: string; status: PayLinkStatus }>('Change status successfully');
