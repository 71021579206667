import { Store } from 'redux';

import { createWrapper } from 'next-redux-wrapper';

import { State } from '../store';
import configureStore from '../store/createStore';

// create a makeStore function
const makeStore = () => configureStore();

// export an assembled wrapper
export const wrapper = createWrapper<Store<State>>(makeStore, { debug: false });
