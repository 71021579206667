import { createReducer } from '@reduxjs/toolkit';

import { initialState } from './state';
import * as acts from './actions';

export default createReducer(initialState, {
  [acts.fetchTracking.toString()]: (state, { payload: { barcode } }: ReturnType<typeof acts.fetchTracking>) => ({
    ...state,
    barcode,
    trackingList: [],
  }),
  [acts.fetchTrackingSuccess.toString()]: (
    state,
    { payload: { list } }: ReturnType<typeof acts.fetchTrackingSuccess>
  ) => ({
    ...state,
    trackingList: list,
  }),
});
