/* eslint-disable @typescript-eslint/no-explicit-any */

// @ts-ignore
import omit from 'lodash.omit';

import { i18n } from '../../../i18n';
import { deepFind } from '../../../helpers/array';

type Dict = Record<string, any>;

function arrayToDict(list: Record<string, string | string[]>[]): Dict {
  const hasObject = (arr: (string | object)[]) => arr.every((item) => typeof item === 'object');

  return list.reduce((acc: Dict, cur): Dict => {
    const hasItems = Boolean(cur && Object.keys(cur).length);

    if (hasItems) {
      Object.entries(cur).forEach(([key, value]) => {
        if (Array.isArray(value) || typeof value === 'string') {
          // handle nested object
          if (hasObject(value as any)) {
            acc[key] = arrayToDict(value as any);

            return;
          }

          acc[key] = Array.isArray(value) ? value.join('\n') : value;
        }
      });
    }

    return acc;
  }, {} as Dict);
}

function mergeRules(...rules: Array<string | any>): string {
  return rules.filter(Boolean).join('\n');
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const extractRatesErrors = (data: Record<string, any>): { errors: any; alerts: any } => {
  const errors = {
    // sender
    sender_dropOffPoint: deepFind(data, 'custom_attributes.dropoff_point'),
    sender_sendMethod: deepFind(data, 'custom_attributes.sending_method'),
    sender_email: deepFind(data, 'sender.email'),
    sender_phone: deepFind(data, 'sender.phone'),
    sender_fixedPhone: deepFind(data, 'sender.fixed_phone'),
    sender_firstName: deepFind(data, 'sender.first_name'),
    sender_lastName: deepFind(data, 'sender.last_name'),
    sender_street: deepFind(data, 'sender.address.street'),
    sender_buildingNumber: deepFind(data, 'sender.address.building_number'),
    sender_apartments: deepFind(data, 'sender.address.apartments'),
    sender_houseName: deepFind(data, 'sender.address.house_name'),
    sender_city: deepFind(data, 'sender.address.city'),
    sender_postCode: deepFind(data, 'sender.address.post_code'),
    sender_dispatchDate: deepFind(data, 'dispatch_date'),

    // receiver
    receiver_phone: deepFind(data, 'receiver.phone'),
    receiver_firstName: deepFind(data, 'receiver.first_name'),
    receiver_lastName: deepFind(data, 'receiver.last_name'),
    receiver_street: deepFind(data, 'receiver.address.street'),
    receiver_apartments: deepFind(data, 'receiver.address.apartments'),
    receiver_region: deepFind(data, 'receiver.address.region'),
    receiver_buildingNumber: deepFind(data, 'receiver.address.building_number'),
    receiver_city: deepFind(data, 'receiver.address.city'),
    receiver_postCodeAutoComplete: deepFind(data, 'receiver.address.post_code'),
  };

  const sender = deepFind(data, 'sender');

  if (Array.isArray(sender)) {
    const rules = arrayToDict(sender);

    const fields = {
      sender_email: 'email',
      sender_phone: 'phone',
      sender_fixedPhone: 'fixed_phone',
      sender_firstName: 'first_name',
      sender_lastName: 'last_name',
      sender_street: 'address.street',
      sender_buildingNumber: 'address.building_number',
      sender_apartments: 'address.apartments',
      sender_houseName: 'address.house_name',
      sender_city: 'address.city',
      sender_postCode: 'address.post_code',
    };

    Object.entries(fields).forEach(([key, path]) => {
      // @ts-ignore
      errors[key] = mergeRules(errors[key], deepFind(rules, path));
    });
  }

  let alerts: any = {};

  const is500 = typeof (data as any) === 'string';

  if (is500) {
    alerts['500'] = i18n.t('common:errors.500');
  } else {
    alerts = {
      ...omit(data, ['dispatch_date', 'receiver', 'sender', 'custom_attributes']),
    };

    const custom_attributes = deepFind(data, 'custom_attributes');

    if (Array.isArray(custom_attributes)) {
      alerts = {
        ...alerts,
        ...arrayToDict(custom_attributes),
      };
    }

    // handle non translated errors
    const ua_city_courier = deepFind(data, 'receiver.address.ua_city_courier');

    if (ua_city_courier) {
      alerts.ua_city_courier = i18n.t('rates:rates.validation.ua_city_courier');
    }
  }

  return {
    errors,
    alerts,
  };
};
