import React from 'react';
import dynamic from 'next/dynamic';

import { isHMR, isNewYear } from '../../config';

const Snow = dynamic(() => import('./let-it-snow'), {
  ssr: false,
});

export const LetItSnow: React.FC = () => {
  if (isHMR || !isNewYear) {
    return null;
  }

  const props = {
    windPower: 1,
    speed: 1,
    count: 15,
    size: 5,
    opacity: 0.7,
    images: ['/static/img/icons/snow.png'],
  };

  return <Snow {...props} />;
};
