import { call, debounce, put, select } from 'redux-saga/effects';

import { wrapSaga } from '../../../store/sagas/helpers';

import { isRusPost } from '../../../config/providers';
import { Country } from '../../../config/countries';

import { City } from '../../../client';

import { actions as acts } from '../../rates';

import * as fromProviders from '../../providers/selectors';
import * as fromRates from '../../rates/selectors';

/**
 * Validate receiver city
 */
function* receiverCitiesWorker() {
  const { provider: toProvider }: Country = yield select(fromProviders.getInboundCountry);

  if (isRusPost(toProvider)) {
    const isCourier = yield select(fromRates.isCourierDelivery);

    if (!isCourier) {
      return;
    }

    const { city, citiesList = [] as City[] } = yield select(fromRates.getReceiver);

    const validName = !city || citiesList.some(({ display_name }) => display_name === city);

    if (!validName) {
      yield put(
        acts.setReceiver({
          city: '',
          region: '',
        })
      );
    }
  }
}

export function* receiverFormCityWatcher() {
  yield debounce(500, [acts.setReceiver], function* () {
    yield call(wrapSaga, receiverCitiesWorker, false, false);
  });
}
