import { call, put } from 'redux-saga/effects';

import { showLoader, hideLoader } from '../../entities/app/actions';
import * as appActs from '../actions';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* wrapSaga(generator: any, throwError?: boolean, emitLoader = true) {
  // display loader
  if (emitLoader) {
    yield put(showLoader());
  }

  try {
    yield call(generator);
  } catch (error) {
    if (throwError) {
      throw error;
    }

    yield put(
      appActs.handleErrorAct({
        error: error.response,
      })
    );
  } finally {
    // hide loader
    if (emitLoader) {
      yield put(hideLoader());
    }
  }
}
