import { Country, getCountry } from '../../config/countries';
import { Providers } from '../../config/providers';
import { Delivers, ProvidersMap } from '../../client';

export type ProviderFieldMode = 'calculator' | 'delivers';

export interface ProvidersState {
  inboundList: Providers[];
  providersPair: null | ProvidersMap;
  mode: ProviderFieldMode | null;
  noDocs: boolean;
  dispatchCountry: Country;
  inboundCountry: Country;
  delivers: Delivers[];
  bookService: string;
  parcelTemplate: string;
  weight: number;
  width: number;
  height: number;
  length: number;
  price: string;
  deliveryDiff: string;
  priceEur: string;
  discountPrice: string;
  discount: number | null;
  processPiraeus: boolean;
  errors: Record<string, string>;
  alertErrors: Record<string, string>;
}

export const initialState: ProvidersState = Object.freeze({
  inboundList: [],
  providersPair: null,
  mode: null,
  noDocs: false,
  dispatchCountry: getCountry('pl'),
  inboundCountry: getCountry('ua'),
  delivers: [],
  bookService: '',
  parcelTemplate: '',
  weight: 1,
  width: 8,
  height: 38,
  length: 64,
  price: '',
  deliveryDiff: '',
  priceEur: '',
  discountPrice: '',
  discount: null,
  processPiraeus: false,
  errors: {},
  alertErrors: {},
});
