import { createReducer } from '@reduxjs/toolkit';
import { initialState } from './state';
import { hideLoader, showLoader } from './actions';

export default createReducer(initialState, {
  [showLoader.toString()]: (state) => ({
    ...state,
    loader: true,
  }),
  [hideLoader.toString()]: (state) => ({
    ...state,
    loader: false,
  }),
});
