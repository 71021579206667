import React from 'react';
import App from 'next/app';
import { Router } from 'next/router';

import { ThemeProvider } from 'styled-components';

import { wrapper } from '../redux';

import * as gtag from '../helpers/gtag';
import { scrollToTop } from '../helpers/scroll/scrollToTop';

import { appWithTranslation } from '../i18n';

import { ElementTranslationProvider } from '../components/element';
import { LetItSnow } from '../components/let-it-snow';

import '../assets/sass/vendor/index.scss';
import '../assets/sass/common.scss';

// track page view with google tag manager
Router.events.on('routeChangeComplete', (url) => {
  gtag.pageview(url);

  scrollToTop();
});

const theme = {
  sizes: {
    header: {
      height: '60px',
    },
  },
  colors: {
    primary: '#409eff',
  },
};

class MyApp extends App {
  render() {
    const { Component, pageProps } = this.props;
    return (
      <>
        <ThemeProvider theme={theme}>
          <>
            <ElementTranslationProvider>
              <Component {...pageProps} />
            </ElementTranslationProvider>
          </>
        </ThemeProvider>

        <LetItSnow />
      </>
    );
  }
}

export default wrapper.withRedux(appWithTranslation(MyApp));
