const KEY = 'piraeus_payload';

export const storePayload = (payload: Record<string, string>) => {
  localStorage.setItem(KEY, JSON.stringify(payload));
};

export const decodePayload = (): Record<string, string> | null => {
  try {
    const data = localStorage.getItem(KEY);

    if (data) {
      return JSON.parse(data);
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('Piraeus decode error: ', e);
  }

  return null;
};

export const cleanPayload = () => {
  localStorage.removeItem(KEY);
};
