import { all, fork } from 'redux-saga/effects';

// watchers
import { pricingWatcher } from './pricing.saga';
import { courierWatcher } from './post-code.saga';
import { extensionWatcher, extPricingWatcher } from './extension.saga';
import { cleanWatcher, deliversWatcher, providersPairWatcher, providerURLWatcher } from './delivers.saga';
import { submitWatcher } from './submit.saga';
import { paymentsWatcher, piraeusWatcher } from './payments.saga';
import { receiverFormCityWatcher } from './cities.saga';

export default function* root() {
  yield all([
    fork(courierWatcher),
    fork(receiverFormCityWatcher),
    fork(pricingWatcher),
    fork(extensionWatcher),
    fork(extPricingWatcher),
    fork(deliversWatcher),
    fork(providerURLWatcher),
    fork(cleanWatcher),
    fork(providersPairWatcher),
    fork(submitWatcher),
    fork(paymentsWatcher),
    fork(piraeusWatcher),
  ]);
}
