/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable global-require */
import { configureStore as createStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { Task } from '@redux-saga/types';

import reducer from './reducers';
import rootSaga from './sagas';

export default function configureStore(preloadedState = {}) {
  const sagaMiddleware = createSagaMiddleware();
  const middleware = [...getDefaultMiddleware(), sagaMiddleware];

  const store = createStore({
    reducer,
    middleware,
    devTools: process.env.NODE_ENV !== 'production',
    preloadedState,
  });

  let sagaTask: Task = sagaMiddleware.run(function* () {
    yield rootSaga();
  });

  if ((module as any).hot) {
    // Enable Webpack hot module replacement for reducers
    (module as any).hot.accept('./reducers', () => {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const nextRootReducer = require('./reducers').default;
      store.replaceReducer(nextRootReducer);
    });

    // HRM for sagas
    (module as any).hot.accept('./sagas', () => {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const newRootSaga = require('./sagas').default;
      sagaTask.cancel();
      sagaTask.toPromise().then(() => {
        sagaTask = sagaMiddleware.run(function* () {
          yield newRootSaga();
        });
      });
    });
  }

  return store;
}
