import { all, fork } from 'redux-saga/effects';

import client from './client';
import feedback from '../../entities/feedback/saga';
import tracking from '../../entities/tracking/saga';
import providers from '../../entities/providers/saga';
import smartPay from '../../entities/smart-pay/saga';

export default function* root() {
  yield all([fork(client), fork(feedback), fork(tracking), fork(providers), fork(smartPay)]);
}
