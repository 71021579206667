import { call, put, select, takeLatest } from 'redux-saga/effects';

import { wrapSaga } from '../../../store/sagas/helpers';

import { i18n } from '../../../i18n';

import { actions as ratesActs, StepType } from '../../rates';
import * as acts from '../actions';
import * as fromProviders from '../selectors';
import * as fromRates from '../../rates/selectors/shipments.selectors';
import * as formSelectors from '../form.selector';

import * as client from '../../../client';

import { error } from '../../../helpers/alert';

import { scrollToFormError } from '../../../components/rates/helpers/ui';
import { extractRatesErrors } from '../../../components/rates/helpers/api';
import { ShipmentsBody } from '../../../client';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* setApiErrors(data: Record<string, any>) {
  const { errors, alerts } = extractRatesErrors(data);

  yield put(acts.setErrors({ errors }));
  yield put(acts.setAlerts({ errors: alerts }));

  scrollToFormError();
}

function* submitWorker() {
  const weightValid = yield select(formSelectors.isWeightValid);

  if (!weightValid) {
    error(i18n.t('rates:rates.validation.weightMatch'));

    return;
  }

  const body: ShipmentsBody = yield select(formSelectors.getShipmentsBody);
  const { provider } = yield select(fromProviders.getDispatchCountry);

  try {
    // reset all API errors
    yield put(acts.setAlerts({ errors: {} }));
    yield put(acts.setErrors({ errors: {} }));

    const existingShipment = yield select(fromRates.geShipment);

    // check for existing shipment
    if (!existingShipment) {
      // create provider shipment
      const shipment = yield call(client.createShipment, provider, body);
      yield put(ratesActs.setShipment({ shipment }));
    }

    // navigate to payments form
    yield put(ratesActs.setStep({ step: StepType.payment }));
  } catch (e) {
    const { data } = e.response;

    yield call(setApiErrors, data);
  }
}

export function* submitWatcher() {
  yield takeLatest([acts.submitForm], function* () {
    yield call(wrapSaga, submitWorker);
  });
}
