import { BankingDetails, PayLinkTicket } from '../../client';

export enum LinkStep {
  form,
  payment,
}

export const initialState = Object.freeze({
  slug: '',
  details: null as BankingDetails | null,
  ticket: null as PayLinkTicket | null,
  step: LinkStep.form,
});

export type SmartPayState = typeof initialState;
