import { createSelector } from 'reselect';

import { selectors as fromRates, StepType } from '../../rates';
import * as fromProviders from '../selectors';

export const formStateSelector = createSelector(
  fromRates.getActiveStep,
  fromProviders.getDispatchCountry,
  fromProviders.getInboundCountry,
  fromProviders.getParcelWeight,
  fromProviders.getParcelWidth,
  fromProviders.getParcelHeight,
  fromProviders.getParcelLength,
  (step: StepType, { code: from }, { code: to }, weight, width, height, length) => ({
    step,
    from,
    to,
    weight,
    width,
    height,
    length,
  }),
);

export const serviceSelector = createSelector(
  fromProviders.getDispatchCountry,
  fromProviders.getInboundCountry,
  fromProviders.getBookService,
  fromRates.getDeliveryType,
  fromProviders.geParcelTemplate,
  ({ provider: from }, { provider: to }, service, deliveryType, template) => ({
    from,
    to,
    service,
    deliveryType,
    template: template ? template.type : '',
  }),
);
