import * as Sentry from '@sentry/nextjs';

import { all, call, fork, select, takeEvery } from 'redux-saga/effects';

import { handleErrorAct, handleSuccessAct } from '../actions';

import { success, warning } from '../../helpers/alert';
import * as fromSelector from '../../entities/providers/form.selector';

function* handleSuccessWorker({ payload: { message } }: ReturnType<typeof handleSuccessAct>) {
  yield call(success, message);
}

function* handleErrorWorker({ payload: { error } }: ReturnType<typeof handleErrorAct>) {
  if (error && error.data) {
    const shipmentForm = select(fromSelector.getShipmentsBody);

    Sentry.captureException(
      { error },
      {
        level: 'error',
        extra: {
          shipmentForm,
        },
      }
    );

    const message = Object.values(error.data).join('\n');
    yield call(warning, message);
  }
}

// watchers

function* handleSuccessWatcher() {
  yield takeEvery(handleSuccessAct, handleSuccessWorker);
}

function* handleErrorWatcher() {
  yield takeEvery(handleErrorAct, handleErrorWorker);
}

export default function* root() {
  yield all([fork(handleSuccessWatcher), fork(handleErrorWatcher)]);
}
