/* eslint-disable @typescript-eslint/no-explicit-any */
import { all, call, debounce, fork, put, select, takeLatest } from 'redux-saga/effects';

import { Router } from '../../i18n';

import { hideLoader, showLoader } from '../app/actions';
import * as appActs from '../../store/actions';
import * as acts from './actions';
import * as fromSmartPay from './selectors';

import * as client from '../../client';

import { wrapSaga } from '../../store/sagas/helpers';

const statusesToPrevent = [
  client.PayLinkStatus.paid,
  client.PayLinkStatus.expired,
  client.PayLinkStatus.canceled,
  client.PayLinkStatus.failed,
];

function* statusWorker({ payload: { status } }: ReturnType<typeof acts.changeStatusRequest>) {
  const details: client.BankingDetails | null = yield select(fromSmartPay.getDetails);

  // prevent any change for already paid link
  if (details && details.status && statusesToPrevent.includes(details.status)) {
    return;
  }

  const slug: string = yield select(fromSmartPay.getSlug);

  yield call(client.setPayLinkStatus, slug, status);

  yield put(
    acts.changeStatusSuccess({
      status,
      slug,
    })
  );

  if (status === client.PayLinkStatus.canceled) {
    yield call(Router.replace, '/');
  }

  if (status === client.PayLinkStatus.paid) {
    yield call(Router.push, '/?successfulPayment=true');
  }
}

function* ticketWorker() {
  const details: client.BankingDetails = yield select(fromSmartPay.getDetails);

  const ticket: client.PayLinkTicket = yield call(client.createPayLinkTicket, {
    id: details.id,
    lang: details.lang,
  });

  yield put(
    acts.createTicketRequestSuccess({
      ticket,
    })
  );
}

function* paymentDataWorker({ payload: { slug } }: ReturnType<typeof acts.fetchPaymentData>) {
  yield put(showLoader());

  try {
    const details = yield call(client.getBankingDetails, slug);

    yield put(acts.fetchPaymentDataSuccess({ details }));
  } catch (error) {
    const { data } = error.response;

    yield put(
      appActs.handleErrorAct({
        error: {
          data,
        },
      })
    );
  }

  yield put(hideLoader());
}

function* changeStatusWatcher() {
  yield debounce(500, [acts.changeStatusRequest], function* (action) {
    yield call(wrapSaga, statusWorker.bind(null, action));
  });
}

function* ticketWatcher() {
  yield debounce(500, [acts.createTicketRequest], function* () {
    yield call(wrapSaga, ticketWorker);
  });
}

function* paymentDataWatcher() {
  yield takeLatest(acts.fetchPaymentData, paymentDataWorker);
}

export default function* root() {
  yield all([fork(changeStatusWatcher), fork(ticketWatcher), fork(paymentDataWatcher)]);
}
