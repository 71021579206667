import { createReducer } from '@reduxjs/toolkit';

import { initialState, ProvidersState } from './state';
import * as acts from './actions';

import { getCountry } from '../../config/countries';
import { getProviderParcelTemplates } from '../../config/providers';
import { extractCurrency, extractPrice } from '../../helpers/currency';
import { difference } from '../../helpers/numbers';

/**
 * `createReducer` function use Immer under the hood
 *  so state updates actually `immutable`
 *  more info here https://redux.js.org/recipes/structuring-reducers/immutable-update-patterns/
 */
export default createReducer(initialState, {
  [acts.fullReset.toString()]: () => ({ ...initialState }),
  [acts.setInboundListAct.toString()]: (
    state: ProvidersState,
    { payload: { inboundList } }: ReturnType<typeof acts.setInboundListAct>
  ) => {
    if (inboundList) {
      state.inboundList = inboundList;
    }
  },
  [acts.setProvidersPairAct.toString()]: (
    state: ProvidersState,
    { payload: { providersPair } }: ReturnType<typeof acts.setProvidersPairAct>
  ) => {
    if (providersPair) {
      state.providersPair = providersPair;
    }
  },
  [acts.setCountryAct.toString()]: (
    state: ProvidersState,
    { payload: { from, to } }: ReturnType<typeof acts.setCountryAct>
  ) => {
    // reset weight to initial
    // because every country provider may have different max weight
    state.weight = initialState.weight;
    state.price = initialState.price;
    state.priceEur = initialState.priceEur;
    state.discountPrice = initialState.discountPrice;
    state.discount = initialState.discount;

    if (from) {
      state.dispatchCountry = getCountry(String(from));
    }

    if (to) {
      state.inboundCountry = getCountry(String(to));
    }

    state.delivers = [];
  },
  [acts.setMode.toString()]: (state: ProvidersState, { payload: { mode } }: ReturnType<typeof acts.setMode>) => {
    state.mode = mode;
  },
  [acts.setDocs.toString()]: (state: ProvidersState, { payload: { noDocs } }: ReturnType<typeof acts.setDocs>) => {
    state.noDocs = Boolean(noDocs);
  },
  [acts.setParcelTemplate.toString()]: (
    state: ProvidersState,
    { payload: { parcelTemplate } }: ReturnType<typeof acts.setParcelTemplate>
  ) => {
    state.parcelTemplate = parcelTemplate;
  },
  [acts.setPrice.toString()]: (
    state: ProvidersState,
    { payload: { price, price_eur = '', discount_price = '', discount = null } }: ReturnType<typeof acts.setPrice>
  ) => {
    state.price = price;
    state.priceEur = price_eur;
    state.discountPrice = discount_price || '';
    state.discount = Number(discount) || null;
  },
  [acts.setWeightAct.toString()]: (
    state: ProvidersState,
    { payload: { weight } }: ReturnType<typeof acts.setWeightAct>
  ) => {
    state.weight = weight;
  },
  [acts.setWidthAct.toString()]: (
    state: ProvidersState,
    { payload: { width } }: ReturnType<typeof acts.setWidthAct>
  ) => {
    state.width = width;
  },
  [acts.setHeightAct.toString()]: (
    state: ProvidersState,
    { payload: { height } }: ReturnType<typeof acts.setHeightAct>
  ) => {
    state.height = height;
  },

  [acts.setLengthAct.toString()]: (
    state: ProvidersState,
    { payload: { length } }: ReturnType<typeof acts.setLengthAct>
  ) => {
    state.length = length;
  },
  [acts.setDelivers.toString()]: (
    state: ProvidersState,
    { payload: { delivers } }: ReturnType<typeof acts.setDelivers>
  ) => {
    state.delivers = delivers;
  },
  [acts.setDeliveryDiff.toString()]: (
    state: ProvidersState,
    { payload: { courier, department } }: ReturnType<typeof acts.setDeliveryDiff>
  ) => {
    const hasDiscount = courier.discount && courier.discount > 0;
    const field = hasDiscount ? 'discount_price' : 'price';

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const a = department[field]!;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const b = courier[field]!;

    const price = extractPrice(a);
    const discounted = extractPrice(b);

    const diff = Math.abs(difference(price, discounted)).toFixed(2);

    const currency = extractCurrency(a);

    state.deliveryDiff = `${diff} ${currency}`;
  },

  // booking flow
  [acts.bookService.toString()]: (
    state: ProvidersState,
    { payload: { service } }: ReturnType<typeof acts.bookService>
  ) => {
    state.bookService = typeof service === 'string' ? service : service.type;

    // find parcel templates
    const [template] = getProviderParcelTemplates(state.dispatchCountry.provider, state.bookService);
    state.parcelTemplate = template ? template.type : '';
  },

  [acts.processPiraeusTicket.toString()]: (state: ProvidersState) => {
    state.processPiraeus = true;
  },

  [acts.setErrors.toString()]: (state: ProvidersState, { payload: { errors } }: ReturnType<typeof acts.setErrors>) => {
    state.errors = errors;
  },
  [acts.resetError.toString()]: (state: ProvidersState, { payload: { key } }: ReturnType<typeof acts.resetError>) => {
    if (state.errors) {
      state.errors[key] = '';
    }
  },
  [acts.setAlerts.toString()]: (state: ProvidersState, { payload: { errors } }: ReturnType<typeof acts.setAlerts>) => {
    state.alertErrors = errors;
  },
  [acts.resetAlert.toString()]: (state: ProvidersState, { payload: { key } }: ReturnType<typeof acts.resetAlert>) => {
    delete state.alertErrors[key];
  },
});
