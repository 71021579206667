import { call, debounce, put, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import { Country } from '../../../config/countries';

import { wrapSaga } from '../../../store/sagas/helpers';

import { actions as ratesActs, actions as acts, Extension, ExtensionTypes } from '../../rates';
import * as fromRates from '../../rates/selectors';
import * as fromProviders from '../selectors';

import { getExtensions, getExtensionsPrice } from '../../../client';

function* extPricingWorker({ payload: { ext: service } }: PayloadAction<{ ext: ExtensionTypes }>) {
  const value: number = yield select(fromRates.getDeclarationFullPrice);
  const { currency }: Country = yield select(fromProviders.getDispatchCountry);

  try {
    const price = yield call(getExtensionsPrice, {
      service,
      value,
      currency,
    });

    yield put(
      ratesActs.updateServicePrice({
        price,
        service,
      })
    );
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
}

function* extensionWorker() {
  const list: Extension[] = yield call(getExtensions);

  const extensions = list.reduce((acc, cur) => {
    acc[cur.slug] = cur;

    return acc;
  }, {} as Record<string, Extension>);

  yield put(
    acts.fetchExtensionsSuccess({
      extensions,
    })
  );
}

export function* extPricingWatcher() {
  yield debounce(500, [ratesActs.checkExt], extPricingWorker);
}

export function* extensionWatcher() {
  yield debounce(500, [acts.fetchExtensions], function* () {
    yield call(wrapSaga, extensionWorker, false, false);
  });
}
