import { call, debounce, put, select } from 'redux-saga/effects';

import { wrapSaga } from '../../../store/sagas/helpers';

import { actions as acts } from '../../rates';

import { getPostCodeCourier } from '../../../client';

import * as fromRates from '../../rates/selectors';

function* courierWorker() {
  const { postCode } = yield select(fromRates.getReceiver);

  if (postCode) {
    const courier = yield call(getPostCodeCourier, postCode);

    yield put(
      acts.fetchPostCodeServiceSuccess({
        courier,
      })
    );
  }
}

export function* courierWatcher() {
  yield debounce(500, [acts.fetchPostCodeCourier], function* () {
    yield call(wrapSaga, courierWorker, false, false);
  });
}
