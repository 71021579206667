import { call, put, select, takeLatest } from 'redux-saga/effects';

import { wrapSaga } from '../../../store/sagas/helpers';

import * as smartPayActs from '../../smart-pay/actions';
import * as fromSmartPay from '../../smart-pay/selectors';
import * as acts from '../actions';
import * as client from '../../../client';

import { i18n, Router } from '../../../i18n';

import { error } from '../../../helpers/alert';
import * as piraeus from '../../../helpers/piraeus';
import { PaymentMethods } from '../../../config/providers';

import { getLangCode } from '../../../helpers/i18n';

const extractTicket = (xml: string): string => {
  const tag = '<TranTicket>';
  const closingTag = '</TranTicket>';

  return xml.slice(xml.indexOf(tag) + tag.length, xml.indexOf(closingTag));
};

function* piraeusWorker({ payload: { shipment, provider, linkID } }: ReturnType<typeof acts.createPiraeusTicket>) {
  try {
    let payload: Record<string, string> = {};

    if (provider && shipment) {
      const { response } = yield call(client.createPiraeusTicket, {
        service: provider,
        shipment_id: shipment,
        lang: getLangCode(),
      });

      const ticket = extractTicket(response);

      payload = {
        ticket,
        shipment: shipment.toString(),
        provider,
      };
    } else if (linkID) {
      const { response } = yield select(fromSmartPay.getTicket);
      const ticket = extractTicket(response);

      payload = {
        ticket,
        linkID,
      };
    }

    piraeus.storePayload(payload);

    yield put(acts.processPiraeusTicket());
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('[piraeusWorker]', e);
    error('Server Error');
  }
}

function* paymentsWorker({
  payload: { provider, shipment, orderID, paymentType, linkID },
}: ReturnType<typeof acts.payment>) {
  try {
    let successful = true;

    if (linkID) {
      yield put(
        smartPayActs.fetchPaymentData({
          slug: linkID,
        })
      );

      yield put(
        smartPayActs.changeStatusRequest({
          status: client.PayLinkStatus.paid,
        })
      );
    } else {
      try {
        yield call(client.createPayments, provider, {
          shipment,
          payment_type: paymentType,
          order_id: orderID,
          lang: getLangCode(),
        });
      } catch (e) {
        successful = false;
      }
    }

    if (paymentType === PaymentMethods.Piraeus) {
      piraeus.cleanPayload();
    }

    if (successful) {
      yield call(Router.push, '/?successfulPayment=true');
    } else {
      const lang = i18n.language;

      Router.push(`/${lang}/payments/[...reason]`, `/${lang}/payments/payment-failed`).then();
    }
  } catch (e) {
    error('Server Error');
  }
}

export function* piraeusWatcher() {
  yield takeLatest([acts.createPiraeusTicket], function* (action) {
    yield call(wrapSaga, piraeusWorker.bind(null, action));
  });
}

export function* paymentsWatcher() {
  yield takeLatest([acts.payment], function* (action) {
    yield call(wrapSaga, paymentsWorker.bind(null, action));
  });
}
