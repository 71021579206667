import { call, debounce, put, select } from 'redux-saga/effects';

import { wrapSaga } from '../../../store/sagas/helpers';

import { actions as ratesActs } from '../../rates';
import * as acts from '../actions';

import * as fromRates from '../../rates/selectors';
import * as fromExts from '../../rates/selectors/extensions.selectors';
import * as selectors from '../selectors';

import { getPricing } from '../../../client';

function* pricingWorker() {
  const can = yield select(selectors.isCalculator);

  if (!can) {
    return;
  }

  const from = yield select(selectors.getDispatchCountry);
  const to = yield select(selectors.getInboundCountry);
  const weight = yield select(selectors.getParcelWeight);
  const value = yield select(fromRates.getDeclarationFullPrice);
  const extensions: number[] = yield select(fromExts.getActiveExtensions);

  const { data: pricing } = yield getPricing({
    country: from.code,
    destination_country: to.code,
    weight,
    ...(extensions.length
      ? {
          value,
          extensions,
        }
      : {}),
  });

  yield put(acts.setPrice(pricing));
}

export function* pricingWatcher() {
  yield debounce(
    500,
    [acts.setMode, acts.setCountryAct, acts.setWeightAct, ratesActs.checkExt, ratesActs.unCheckExt],
    function* () {
      yield call(wrapSaga, pricingWorker, false, false);
    }
  );
}
