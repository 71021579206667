import { combineReducers } from 'redux';

import app from '../../entities/app/reducers';
import i18n from '../../entities/i18n/reducers';
import tracking from '../../entities/tracking/reducers';
import providers from '../../entities/providers/reducers';
import { reducer as rates } from '../../entities/rates';
import smartPay from '../../entities/smart-pay/reducers';

export default combineReducers({
  app,
  i18n,
  tracking,
  providers,
  rates,
  smartPay,
});
