import { createSelector } from 'reselect';

import { State } from '../../store';
import { BankingDetails } from '../../client/banking';

const getState = ({ smartPay }: State) => smartPay;

export const getSlug = createSelector(getState, ({ slug }) => slug);

export const getDetails = createSelector(getState, ({ details }) => details || {});
// @ts-ignore
export const getLinkLang = createSelector(getDetails, ({ lang }: BankingDetails) => lang || null);

export const getActiveStep = createSelector(getState, ({ step }) => step);

export const getTicket = createSelector(getState, ({ ticket }) => ticket);
